import { IMAGES } from "@assets"
import { giftCardService } from "@services";
import { LANGUAGES } from "@i18n";

// components
import VueLoadImage from "vue-load-image";
import { ScreenLoading, GiftCardItem } from "@components";
import Header from "./components/Header/Header.vue";

export default {
    name: "GiftCard",
    components: {
        Header,
        GiftCardItem,
        ScreenLoading,
        "vue-load-image": VueLoadImage,
    },
    data() {
        return {
            IMAGES,
            isFetchingPage: true,
            giftCardContent: {},
            giftCardItem: {},
            giftCardType: null,
            giftCardImageOriginal: '',
            showGiftCardImage: true,
            truncateMessageSetting: {
                useTruncate: true,
                truncateFromLength: 89,
            }
        }
    },
    computed: {
        giftCardLogo: function () {
            // Case gift card issue content generate by g4b
            if (this.giftCardContent.is_generated_by_g4b && this.giftCardContent.full_path_image) {
                return this.giftCardContent.full_path_image;
            }

            // 1. Get gift card's logo
            if(this.giftCardContent?.gift_card?.full_path_logo_image) {
                return this.giftCardContent?.gift_card?.full_path_logo_image;
            }

            // 2. Has data when generete before issue 1221
            if(this.giftCardContent.full_path_image) {
                return this.giftCardContent.full_path_image;
            } 
            
            // 3. display default logo
            return null;
        },
        truncateMessage: function () {
            let suffix = '...';
            let length = this.truncateMessageSetting.truncateFromLength;
            let message = this.giftCardContent.message;

            // If not use truncate message
            if(!this.isValidTruncateMessage) {
                return message;
            }

            return message.substring(0, length) + suffix;
        },
        // Check condition to truncate message
        isValidTruncateMessage: function () {
            let length = this.truncateMessageSetting.truncateFromLength;
            let message = this.giftCardContent.message;

            // Unable truncate if not too long
            if(!message || message.length < length) {
                return false;
            }

            return this.truncateMessageSetting.useTruncate && message.length > length;
        }
    },
    methods: {
        findGiftCard: async function () {
            let giftCardUUID = this.$route.params.uuid;

            const result = await giftCardService.findByUUID(giftCardUUID);

            if (
                result &&
                result?.data?.gift_card_issue_content &&
                result?.data?.gift_card_item &&
                result?.data?.gift_card_type
            ) {
                this.giftCardContent = result.data.gift_card_issue_content;
                this.giftCardItem = result.data.gift_card_item;
                this.giftCardType = result.data.gift_card_type;

                // Set gift card language
                this.$i18n.locale = LANGUAGES[this.giftCardItem.language];

                // Start render
                this.isFetchingPage = false;
            } else {
                // Redirect to page 404 error
                this.$router.push({ path: '/error-404' }).catch(() => {});
            }
        },
        clickMoreButton: function () {
            this.truncateMessageSetting.useTruncate = false;
        },
        handleGiftCardImageError() {
            this.showGiftCardImage = false;
    
            // Set Original Gift card image
            let fullPathImage = this.giftCardContent?.gift_card?.full_path_image ?? '';
            this.giftCardImageUrlOriginal = fullPathImage.replace(/\.(?=[^.]*$)/, '_original.');
        },
    },
    created() {
        // fetch data
        this.findGiftCard();
    }
};
