import { IMAGES } from "@assets";

// components
import VueLoadImage from "vue-load-image";
import { BaseHeader } from "@components"

export default {
    name: "GiftCardHeader",
    components: {
        "vue-load-image": VueLoadImage,
        BaseHeader
    },
    data() {
        return {
            IMAGES,
        };
    },
    props: {
        logo: {
            default: null,
        }
    }
}
